// import our polyfills that we need
import 'events-polyfill/src/constructors/CustomEvent'
import 'events-polyfill/src/constructors/Event'
import 'core-js/es/object'

// https://developer.mozilla.org/en-US/docs/Web/API/Element/closest
// For browsers that do not support Element.closest(), but carry support for element.matches()
if (!Element.prototype.matches) {
    Element.prototype.matches =
        Element.prototype.msMatchesSelector ||
        Element.prototype.webkitMatchesSelector;
    }

    if (!Element.prototype.closest) {
    Element.prototype.closest = function(s) {
        var el = this;

        do {
        if (Element.prototype.matches.call(el, s)) return el;
        el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);
        return null;
    };
}

